import {
  REFRESHTABLE_COMPLAIN,
  DIALOG_COMPLAIN_DELETE,
  DIALOG_COMPLAIN_DELETE_LOADING,
  DIALOG_COMPLAIN_EDIT,
  DIALOG_COMPLAIN_EDIT_LOADING,
  DIALOG_COMPLAIN_EDIT_CLEARFIELD,
  SET_COMPLAIN
} from '../constants/contactusConstants';
import _ from 'lodash';

const initialState = {
  refreshtable: false,
  data: [],
  dialogedit: {
    clearfield: false,
    open: false,
    loading: false,
    data: null
  },
  dialogdelete: {
    open: false,
    loading: false,
    data: null
  }
};

const contactusReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESHTABLE_COMPLAIN:
      let dialogdata = {}
      if (action.payload) {
        dialogdata = {
          dialogedit: {
            open: false,
            loading: false,
            data: null
          },
          dialogdelete: {
            open: false,
            loading: false,
            data: null
          }
        }
      };
      return {
        ...state,
        ...dialogdata,
        refreshtable: action.payload,
      };

    case SET_COMPLAIN:
      return {
        ...state,
        data: action.payload
      };

    case DIALOG_COMPLAIN_EDIT:
      return {
        ...state,
        dialogedit: action.payload
      };

    case DIALOG_COMPLAIN_EDIT_CLEARFIELD:
      return {
        ...state,
        dialogedit: {
          ...state.dialogedit,
          clearfield: action.payload,
        }
      };

    case DIALOG_COMPLAIN_EDIT_LOADING:
      return {
        ...state,
        dialogedit: {
          ...state.dialogedit,
          loading: action.payload,
        }
      };

    case DIALOG_COMPLAIN_DELETE:
      return {
        ...state,
        dialogdelete: action.payload
      };

    case DIALOG_COMPLAIN_DELETE_LOADING:
      return {
        ...state,
        dialogdelete: {
          ...state.dialogdelete,
          loading: action.payload,
        }
      };

    default:
      return state;
  }
};

export default contactusReducer;