export const REFRESHTABLE_COMPLAIN = 'REFRESHTABLE_COMPLAIN';
export const DIALOG_COMPLAIN_DELETE_LOADING = 'DIALOG_COMPLAIN_DELETE_LOADING';
export const SET_COMPLAIN = 'SET_COMPLAIN';
export const DIALOG_COMPLAIN_EDIT_LOADING = 'DIALOG_COMPLAIN_EDIT_LOADING';
export const DIALOG_COMPLAIN_EDIT_CLEARFIELD = 'DIALOG_COMPLAIN_EDIT_CLEARFIELD';
export const DIALOG_COMPLAIN_DELETE = 'DIALOG_COMPLAIN_DELETE';
export const DIALOG_COMPLAIN_EDIT = 'DIALOG_COMPLAIN_EDIT';




