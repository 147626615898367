import { lazy } from "react";
const ValidateCode = lazy(() => import("./components/ValidateCode"));
const CouponType = lazy(() => import("./components/CouponType"));
const Blacklist = lazy(() => import("./components/Blacklist"));
const Customer = lazy(() => import("./components/Customer"));
const Contactus = lazy(() => import("./components/Contactus"));


export const routes = [   
    {
        path: "/customer/information",
        exact: true,
        component: Customer,
        name: "information"
    },
    {
        path: "/customer/contactus",
        exact: true,
        component: Contactus,
        name: "contactus"
    },   
    {
        path: "/validatecode",
        exact: true,
        component: ValidateCode,
        name: "validatecode"
    },    
    {
        path: "/coupontype",
        exact: true,
        component: CouponType,
        name: "coupontype"
    },   
    {
        path: "/blacklist",
        exact: true,
        component: Blacklist,
        name: "blacklist"
    },    
];